import { Tooltip } from '@progress/kendo-react-tooltip'
import React from 'react'
import * as Constants from '../../constants'
import * as Integrations from '../integrations'
import { IntegrationConfigRenderBase, ReconnectIntegrationModal } from './integrationConfigRenderBase'
import { InputSearch } from '../../../input-search/component'
import { isTruthy } from '../../../../generic/utility'
import { isEmpty } from 'lodash'
import { Flag } from '../settingsIntegrations'
import * as Flash from '../../../flash'

declare const RASA_SELF_SERVICE_API: string
declare const RASA_SALESFORCE_STANDARD_CONTACT_PACKAGE_URL: string
declare const RASA_SALESFORCE_RICH_CONTACT_PACKAGE_URL: string
declare const RASA_IMAGE_API_ENDPOINT: string

export class SalesforceConfigRender extends IntegrationConfigRenderBase {

  protected initializeCustomProps() {
    this.props.propertiesChanged({
      loadSegmentField: true,
      segmentFieldLabel: 'Segment Code Field',
    })
  }

  protected formatedFilterDescription() {
    return this.filter.description
  }

  protected componentUpdates() {
    this.setState({
      showCustomFilter: true,
    })
    if (this.props.data.system_api_attribute) {
      this.props.propertiesChanged({
        [Constants.SalesForceFields.SUBSCRIBED_FIELD_NAME]: JSON.parse(this.props.data.system_api_attribute)
          .optout_field_name,
      })
    }
    if (this.props.data.fieldMappings) {
      const emailMapping = this.findFieldMapping('email')
      if (!emailMapping || (emailMapping && !emailMapping.target_field_name)) {
        this.props.propertiesChanged({
          loadEmailField: true,
          emailFieldLabel: 'Email Field',
        })
      }
    }
  }

  protected buildPopUpUrl(): string {
    if (this.props.clientIds) {
      const systemName = this.props.data.systemName
      let decodedState = this.communityId
      if (this.isRichIntegration()) {
        decodedState += `|rich_integration`
      }
      const encodedState = btoa(decodedState)
      return `${this.systemInfo.keyUrl}${encodedState}&client_id=${this.props.clientIds[systemName]}&redirect_uri=${RASA_SELF_SERVICE_API}/integrations/${systemName.toLowerCase()}-oauth-callback`
    }
  }

  protected renderHtml() {
    return <div>
      <div className="settings-system-wrapper">
        <ReconnectIntegrationModal data={this.props.modals} closeModal={this.props.closeModal} title="WARNING"/>
        <div className="settings-integrations-single-system-wrapper" key={this.props.data.id}>
          <div className="settings-integrations-single-system-logo">{this.systemInfo.selectIcon}</div>
          <div className="settings-integrations-single-system-details">
            <div className="settings-integrations-single-system-details-1">
              <div className="settings-integrations-single-system-name-details">
                {this.props.data.display_name} {this.isRichIntegration() ? '- Rich' : ''}
              </div>
              <Flag text="Connected" status={!!this.props.data.is_active && (!this.isRichIntegration() || this.isRichIntegrationChecked())}/>
            </div>
          </div>
        </div>
      </div>
      {this.renderCustomBox()}
      <div className="clearfix"/>
      {this.renderTwoWayStatus()}
      <br/>
      <div className="clearfix"/>
      {this.renderFilter()}
      {this.renderContactCount()}
      {this.renderFieldMapping()}
      <div className="clearfix"/>
      {this.renderSyncNewCustomers()}
      <div className="clearfix"/>
      <div className="connect-disconnect-wrapper">
        {this.renderConnect()}
        {this.renderDisconnect()}
      </div>
    </div>
  }

  protected renderIntegrationTips() {
    let integrationType = 'Standard'
    let packageInstallationUrl = RASA_SALESFORCE_STANDARD_CONTACT_PACKAGE_URL
    let installationDemoURL = `${RASA_IMAGE_API_ENDPOINT}/self-service-logos/path/2024-01-25/files/6fe48334-2873-43e2-95c4-c6920f7649ea?h=270`
    if (this.isRichIntegration()) {
      integrationType = 'Rich'
      packageInstallationUrl = RASA_SALESFORCE_RICH_CONTACT_PACKAGE_URL
      installationDemoURL = `${RASA_IMAGE_API_ENDPOINT}/self-service-logos/path/2024-01-25/files/d624949c-22a7-48d9-a5d1-57acced221a4?h=270`
    }
    return <div className="useful-links">
        <a target="_blank" href={packageInstallationUrl}>
          Install rasa.io {integrationType} Integration Fields Package.
        </a>
        <div className="salesforce-installation-demo-image">
          <div className="demo-example-img">
          <img src={installationDemoURL} />
          </div>
        </div>
        <a target="_blank" href="https://help.rasa.io/salesforce-integration-documentation">
          Read more details about the integration in our Help Center
        </a>
      </div>
  }

  protected renderFilterDropdown() {
    return <>
      <div>
        <div>
          <Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
            <div className="field-label-credentials">
          <span title={`Select your Custom Filter`}>
            'Custom Filter (Required)'
          </span>
            </div>
          </Tooltip>
          <div className="input-wrapper">
            {!this.props.data.is_active
              ? <div>
                  <InputSearch
                  className="input-search"
                  placeholder={'Custom Field'}
                  data={this.getDropdownData(this.state.filters, 'filter')}
                  type="search"
                  isDisabled={false}
                  value={this.selectedListType(this.state.filters, this.props.data.selectedFilterId)}
                  textFiled="name"
                  onChange={(e) => this.onInputSearchChange(e)}/>
                  <i onClick={() => {
                    this.setState({isLoading: true})
                    this.getFilters().then(() => {
                      this.setState({isLoading: false})
                    })
                  }} className="fa fa-refresh"></i>
                </div>
              : <div className={`field-textbox field-textbox- system-api-key`}>
                {(this.props.data.selectedFilterName || '')}
              </div>
            }
          </div>
        </div>
      </div>
      <div>
        <div>
          <Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
            <div className="field-label-credentials">
          <span title={`Select your Custom Opt Out Field`}>
            'Custom Opt Out Field'
          </span>
            </div>
          </Tooltip>
          <div className="input-wrapper">
            {!this.props.data.is_active
              ? <InputSearch
                placeholder={'Select Custom Opt Out Field'}
                data={this.getDropdownData(this.state.filters, 'filter', false)}
                type="search"
                isDisabled={false}
                value={this.selectedListType(this.state.filters, this.props.data[Constants.SalesForceFields.SUBSCRIBED_FIELD_NAME])}
                textFiled="name"
                onChange={(e) => this.props.propertiesChanged({
                  [Constants.SalesForceFields.SUBSCRIBED_FIELD_NAME]: e.value})}/>
              : <div className={`field-textbox field-textbox- system-api-key`}>
                {(this.props.data[Constants.SalesForceFields.SUBSCRIBED_FIELD_NAME] || '')}
              </div>
            }
          </div>
        </div>
      </div>
    </>
  }

  protected renderSelectedFilters() {
    return <div>
      {this.props.data.is_active && this.props.data.selectedFilterName
        ? <div>
          {`You have successfully connected
        ${this.props.data.selectedFilterName !== Constants.ALL_ROLES
            ? ` ${this.formatedFilterDescription()}:`
            : ':'}
        `}
          <div className="selected-filter-name-wrapper">
            {this.props.data[Constants.SalesForceFields.FILTER_NAME] ||
              this.props.data.selectedFilterName}
          </div>
          {this.props.data.is_active && !isEmpty(this.props.data[Constants.SalesForceFields.SUBSCRIBED_FIELD_NAME])
            ? <div>
              <br/>
              Custom Opt Out Field:
              <div className="selected-filter-name-wrapper">
                {this.props.data[Constants.SalesForceFields.SUBSCRIBED_FIELD_NAME]}
              </div>
            </div>
            : null}
          {this.props.data.is_active && this.isFieldMappingExist('segment_code') && this.canSegmentation()
            ? <div>
              <br />
              {this.props.data.segmentFieldLabel}:
              <div className="selected-filter-name-wrapper">
                {this.getFieldMappingName('segment_code')}
              </div>
            </div>
            : null}
        </div>
        : null}
    </div>
  }

  protected richIntegrationCheckBoxChange(isRichSync) {
    const newSystemApiAttribute = {
      ...JSON.parse(this.props.data.system_api_attribute),
      is_full_integration: isRichSync,
    }
    this.props.propertiesChanged({
      system_api_attribute: JSON.stringify(newSystemApiAttribute),
    })
    if (this.props.data.is_active) {
      this.props.saveRecord(this.props.data.id)
        .then((result) => {
          this.props.context.store.dispatch(Flash.showFlashMessage(Integrations.notificationMessages.UpdateSuccess))
        })
    }
  }

  protected isRichIntegrationChecked() {
    return this.props.data && this.props.data.system_api_attribute
      ? JSON.parse(this.props.data.system_api_attribute).is_full_integration === true
      : false
  }

  protected getRichIntegration() {
    return this.isRichIntegration() && (this.state.authenticated || this.props.data.is_active) ?
      <div>
        <div className="outbound-confirm-checkbox-line1 salesforce-custom-field">
          <p className="outbound-confirm-checkbox-text rich-checkbox-label">
            Push engagement data to {this.systemInfo.name}
          </p>
          {this.renderIntegrationTips()}
        </div>
      </div> : <div>{this.renderIntegrationTips()}</div>
  }

  protected shouldRenderDisconnect(): boolean {
    return super.shouldRenderDisconnect() && (!this.isRichIntegration() || this.isRichIntegrationChecked())
  }

  protected renderConnect(): JSX.Element {
    if(this.props.data.is_active && this.isRichIntegration() && !this.isRichIntegrationChecked()){
      return <div className="connect-wrapper">
        <div className="authenticate-wrapper">
          <div className={`authenticate-btn-wrapper-${this.isAuthenticateButtonEnabled() ? '' : 'disabled'}`}>
            <div className={`save-button connect ${this.isAuthenticateButtonEnabled()
              ? 'clickable-item save-button-enabled' : ''}
                authenticate-btn-${this.isAuthenticateButtonEnabled() ? '' : 'disabled'}`}
                 onClick={() => this.connect()}> Connect
            </div>
          </div>
        </div>
      </div>
    }

    return super.renderConnect()
  }

  protected connect() {
    if (this.props.data.is_active && this.isRichIntegration() && !this.isRichIntegrationChecked()) {
      return this.richIntegrationCheckBoxChange(true)
    }
    super.connect()
  }

  protected renderTwoFieldNeededText(): string {
    const optOutField = JSON.parse(this.props.data.system_api_attribute).optout_field_name
    if (optOutField) {
      return Integrations.systemInformation(this.props.data.systemName).twoWayFieldNeededText
        .replace('OptedOutRasa__c', optOutField)
    } else {
      return Integrations.systemInformation(this.props.data.systemName).twoWayFieldNeededText
    }
  }

  protected selectedDifferentFilter(e): boolean {
    if (this.state.existingFilter.type) {
      if (this.state.existingFilter.type === e.type &&
        this.state.existingFilter.id === e.value) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  protected isFinalizeConnectionEnabled() {
    if (this.props.data.loadEmailField) {
      const emailMapping = this.findFieldMapping('email')
      if (emailMapping && !emailMapping.target_field_name) {
        return false
      }
    }
    return super.isFinalizeConnectionEnabled()
  }

  private onInputSearchChange(e,) {
    new Promise((resolve, reject) => {
      this.props.propertiesChanged({
        selectedFilterName: e.value,
        selectedFilterId: e.value,
        selectedFilterType: e.type,
      })
      if (this.selectedDifferentFilter(e)) {
        this.props.openModal(ReconnectIntegrationModal.key, null)
      }
      resolve(null)
    }).then(() => {
      if (isTruthy(this.showContactsCount)) {
        this.getContacts(
          this.props.data.id,
          this.props.data.selectedFilterId,
          this.props.data.selectedFilterName,
        )
      }
      this.setState({ filterSelected: true })
    })
  }

  private getDropdownData(data, listType, includeRasaNewsletter: boolean = true) {
    return data.filter((a) => a.type === listType && (includeRasaNewsletter || a.value !== Constants.DEFAULT_SALESFORCE_CUSTOM_FILTER))
  }

  private selectedListType(data, selectedValue) {
    return data.find((a) => a.value === selectedValue && a.type === 'filter')
  }

}
