import { Tooltip } from '@progress/kendo-react-tooltip'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import React from 'react'
import * as Flash from '../../../flash';
import * as Constants from '../../constants'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'
import { RASA_FIELDS } from '../integrations';

declare const RASA_SELF_SERVICE_API: string

export class PersonifyMCProConfigRender extends IntegrationConfigRenderBase {
  protected supportsFilter: boolean = true
  protected showContactsCount = false
  private systemClientId: string = 'clientId'
  private systemClientSecret: string = 'clientSecret'
  private systemOrganizationId: string = 'storeName'
  private optOutFieldName: string = 'optOutFieldName'
  private optOutReasonFieldName: string = 'optOutReasonFieldName'
  private optOutDateFieldName: string = 'optOutDateFieldName'
  constructor(props: any) {
    super(props)
    this.onOptOutFieldChange = this.onOptOutFieldChange.bind(this)
    this.onFieldMappingChange = this.onFieldMappingChange.bind(this)
  }
  protected initializeCustomProps() {
    if (this.systemApiAttribute && this.systemApiAttribute.client_id) {
      this.props.propertiesChanged({
        [this.systemClientId]: this.systemApiAttribute.client_id,
      })
    }
    if (this.systemApiAttribute && this.systemApiAttribute.client_secret) {
      this.props.propertiesChanged({
        [this.systemClientSecret]: this.systemApiAttribute.client_secret,
      })
    }
    if (this.systemApiAttribute && this.systemApiAttribute.org_id) {
      this.props.propertiesChanged({
        [this.systemOrganizationId]: this.systemApiAttribute.org_id,
      })
    }
    if (this.props.data.fieldMappings) {
      const optOutFieldMapping = this.findFieldMapping('is_subscribed')
      if (optOutFieldMapping) {
        this.props.propertiesChanged({
          [this.optOutFieldName]: optOutFieldMapping.target_field_name,
        })
      }
      const optOutReasonMapping = this.findFieldMapping('unsubscribe_reason')
      if (optOutReasonMapping) {
        this.props.propertiesChanged({
          [this.optOutReasonFieldName]: optOutReasonMapping.target_field_name,
        })
      }
      const optOutDateMapping = this.findFieldMapping('subscription_changed_date')
      if (optOutDateMapping) {
        this.props.propertiesChanged({
          [this.optOutDateFieldName]: optOutDateMapping.target_field_name,
        })
      }
    }
  }

  protected renderSelectedFilters() {
      return <div>
        {this.props.data.is_active && this.props.data.selectedFilterName
          ? <div>
            {`You have successfully connected
          ${this.props.data.selectedFilterName !== Constants.ALL_CONTACTS
              ? ` ${this.formatedFilterDescription()}:`
              : ':'}
          `}
            <div className="selected-filter-name-wrapper">
              {this.props.data[Constants.SalesForceFields.FILTER_NAME] ||
                this.props.data.selectedFilterName}
            </div>
            {this.props.data.is_active && this.isFieldMappingExist(RASA_FIELDS.IS_SUBSCRIBED)
              ? <div>
                <br/>
                Opt Out Field:
                <div className="selected-filter-name-wrapper">
                {this.getRasaFieldMappingName(RASA_FIELDS.IS_SUBSCRIBED)}
                </div>
              </div>
              : null}
            {this.props.data.is_active && this.isFieldMappingExist(RASA_FIELDS.UNSUBSCRIBE_REASON)
              ? <div>
                <br/>
                Opt Out Field:
                <div className="selected-filter-name-wrapper">
                {this.getRasaFieldMappingName(RASA_FIELDS.UNSUBSCRIBE_REASON)}
                </div>
              </div>
              : null}
            {this.props.data.is_active && this.isFieldMappingExist(RASA_FIELDS.SUBSCRIPTION_CHANGED_DATE)
              ? <div>
                <br/>
                Opt Out Field:
                <div className="selected-filter-name-wrapper">
                {this.getRasaFieldMappingName(RASA_FIELDS.SUBSCRIPTION_CHANGED_DATE)}
                </div>
              </div>
              : null}
          </div>
          : null}
      </div>
    }

  protected isAuthenticateButtonEnabled(): boolean {
    return !this.state.isConnecting && !!this.props.data.clientId && !!this.props.data.clientSecret
  }

  protected renderCustomFilter() {
    return <div className="api-key-wrapper">
            <div>
              {this.createTextBox('Opt Out Field Name', this.optOutFieldName, '', this.onOptOutFieldChange)}
            </div>
            <div>
              {this.createTextBox('Opt Out Reason Field Name', this.optOutReasonFieldName, '', this.onFieldMappingChange)}
            </div>
            <div>
              {this.createTextBox('Opt Out Date Field Name', this.optOutDateFieldName, '', this.onFieldMappingChange)}
            </div>
          </div>
  }

  protected connect() {
    const url = this.buildPopUpUrl()
    this.setState({
      isConnecting: true,
    })
    AjaxWrapper.ajax(url, HttpMethod.GET, {})
    .then((result) => {
      if (!!result.success) {
        window.location.href = Constants.INTEGRATIONS_CONFIG_URL + '?id=' + result.payload.communitySystemId + '&operation=' + `${result.operation}${Constants.SUCCESS}`
      } else {
        this.setState({
          isConnecting: false,
        })
        const errMsg = result.payload ? result.payload.message : ''
        this.props.context.store.dispatch(Flash.showFlashError(errMsg))
      }
    }).catch((err) => {
      this.setState({
        isConnecting: false,
      })
      let message = ''
      try {
        message = err.response.message
      } catch (e) {
        message = err.message
      }
      this.props.context.store.dispatch(Flash.showFlashError(message))
    })
  }

  protected buildPopUpUrl(): string {
    return `${RASA_SELF_SERVICE_API}/integrations/${this.props.data.systemName.replace(' ', '').toLowerCase()}-api-callback?state=${this.encodedCommunityIdentifier}&client_id=${this.props.data.clientId}&client_secret=${this.props.data.clientSecret}&org_id=${this.props.data.storeName}&filter_name=${this.props.data.selectedFilterName}`
  }

  protected renderTwoWayStatus() {
    return null
  }

  protected renderCustomBox() {
    return <div className="api-key-wrapper">
      <div>
        {this.createTextBox('Organization ID', this.systemOrganizationId, '', null, true)}
      </div>
      <div>
        {this.createTextBox('Client ID', this.systemClientId, '', null, true)}
      </div>
      <div>
        {this.createTextBox('Client Secret', this.systemClientSecret, '', null, true)}
      </div>
    </div>
  }

  private onOptOutFieldChange(fieldName: string, value: string) {
    if (value) {
      const newSystemApiAttribute = {
        ...JSON.parse(this.props.data.system_api_attribute),
        sync_direction: 'both'
      }
      this.props.propertiesChanged({
        system_api_attribute: JSON.stringify(newSystemApiAttribute),
      })
    } else {
      const newSystemApiAttribute = {
        ...JSON.parse(this.props.data.system_api_attribute),
        sync_direction: 'to_rasa'
      }
      this.props.propertiesChanged({
        system_api_attribute: JSON.stringify(newSystemApiAttribute)
      })
    }
    this.onFieldMappingChange(fieldName, value)
  }

  private onFieldMappingChange(fieldName: string, value: string) {
    this.props.propertiesChanged({
      fieldMappings: this.updateTargetFieldNameFieldMappings(this.props.data.fieldMappings, this.getRasaFieldName(fieldName), value),
      [fieldName]: value,
    })
  }

  private createTextBox(displayName: string, fieldName: string, helpText: string = '', onValueChange: any = null, isRequired: boolean = false) {
    return <div>
      <Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
        <div className="field-label-credentials">
          <span title={helpText || `Input your ${displayName}`}>
            {displayName} {isRequired ? "(Required)" : ""}
          </span>
        </div>
      </Tooltip>
      <div className="input-wrapper">
        {!this.props.data.is_active
        ? <input className={`field-textbox field-textbox-${fieldName} system-api-key`}
            value={(this.props.data[fieldName] || '')}
            onChange={(e) => {
              if (onValueChange) {
                onValueChange(fieldName, e.target.value)
              } else {
                this.props.propertiesChanged({[fieldName]: e.target.value})
              }
            }}
            placeholder={`Enter your ${displayName}`}
          />
        : <div className={`field-textbox field-textbox-${fieldName} system-api-key`}>
            {(this.props.data[fieldName] || '')}
          </div>
        }
      </div>
    </div>
  }
  private getRasaFieldName(fieldName: string): string {
    if (fieldName === this.optOutFieldName) {
      return RASA_FIELDS.IS_SUBSCRIBED
    } else if (fieldName === this.optOutReasonFieldName) {
      return RASA_FIELDS.UNSUBSCRIBE_REASON
    }
    return RASA_FIELDS.SUBSCRIPTION_CHANGED_DATE
  }
}
