import * as Flash from 'components/flash'
import React from 'react'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'
import * as Integrations from '../integrations'
import { Tooltip } from '@progress/kendo-react-tooltip'

declare const RASA_SELF_SERVICE_API: string

export class GrowthZoneConfigRender extends IntegrationConfigRenderBase {

  protected buildPopUpUrl(): string {
    let decodedState = `${this.communityId}|${this.systemApiAttribute.tenant_name}`
    if (this.isRichIntegration()) {
      decodedState += `|rich_integration`
    }
    const encodedState = btoa(decodedState)
    return `https://${this.systemApiAttribute.tenant_name}.growthzoneapp.com/oauth/authorize?client_id=${this.props.clientIds[this.props.data.systemName]}&scope=contact_data+offline_access&response_type=code&redirect_uri=${RASA_SELF_SERVICE_API}/integrations/${this.props.data.systemName.toLowerCase()}-oauth-callback&state=${encodedState}`
  }
  protected getRichIntegration() {
    return this.isRichIntegration() && (this.state.authenticated || this.props.data.is_active) ?
      <div>
        <div className="outbound-confirm-checkbox-line1 salesforce-custom-field">
          <p className="outbound-confirm-checkbox-text rich-checkbox-label">
            Push engagement data to GrowthZone
          </p>
          {this.renderIntegrationTips()}
        </div>
      </div> : <div>{this.renderIntegrationTips()}</div>
  }

  protected renderIntegrationTips() {
    return null
  }
  protected connect() {
    if (this.props.data.is_active && this.isRichIntegration() && !this.isRichIntegrationChecked()) {
      return this.richIntegrationCheckBoxChange(true)
    }
    super.connect()
  }
  protected shouldRenderDisconnect(): boolean {
    return super.shouldRenderDisconnect() && (!this.isRichIntegration() || this.isRichIntegrationChecked())
  }

  protected renderConnect(): JSX.Element {
    if(this.props.data.is_active && this.isRichIntegration() && !this.isRichIntegrationChecked()){
      return <div className="connect-wrapper">
        <div className="authenticate-wrapper">
          <div className={`authenticate-btn-wrapper-${this.isAuthenticateButtonEnabled() ? '' : 'disabled'}`}>
            <div className={`save-button connect ${this.isAuthenticateButtonEnabled()
              ? 'clickable-item save-button-enabled' : ''}
                authenticate-btn-${this.isAuthenticateButtonEnabled() ? '' : 'disabled'}`}
                 onClick={() => this.connect()}> Connect
            </div>
          </div>
        </div>
      </div>
    }
    if (!this.props.data.is_active) {
      return <div className="connect-wrapper">
        {this.renderExistingConnectionModalJSX()}
        {!!this.validateToFinalize() && this.renderFinalizeHtml()}
        {!this.state.authenticated && !this.state.contactClientSuccess &&
          <div className="authenticate-wrapper">
            <div className="authenticate-title-and-text-wrapper">
              <h5 className="authenticate-title"> Quick Tip </h5>
              <div className="authenticate-text">
                <div dangerouslySetInnerHTML={this.renderQuickTip()}/>
              </div>
            </div>
            <div className='input-settings-wrapper'>
              <Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
                <div className="field-label-credentials">
                  <span title={`Input your tenant name here`}>
                    Tenant Name (Required)
                  </span>
                </div>
              </Tooltip>
              <div className="input-wrapper">
                {!this.props.data.is_active
                ? <input className={`field-textbox field-textbox-tenant system-api-key`}
                    value={(this.systemApiAttribute.tenant_name || '')}
                    onChange={(e) => {
                      const newSystemApiAttribute = {
                        ...JSON.parse(this.props.data.system_api_attribute),
                        tenant_name: e.target.value,
                      }
                      this.props.propertiesChanged({system_api_attribute: JSON.stringify(newSystemApiAttribute)})
                    }}
                    placeholder={`Enter your Tenant Name`}
                  />
                : <div className={`field-textbox field-textbox-tenant system-api-key`}>
                    {(this.systemApiAttribute.tenant_name || '')}
                  </div>
                }
              </div>
            </div>
            <div className={`authenticate-btn-wrapper-${this.isAuthenticateButtonEnabled() ? '' : 'disabled'}`}>
              <div className={`save-button connect ${this.isAuthenticateButtonEnabled()
                ? 'clickable-item save-button-enabled' : ''}
                authenticate-btn-${this.isAuthenticateButtonEnabled() ? '' : 'disabled'}`}
                onClick={() => this.connect()}> Authenticate
              </div>
            </div>
          </div>
        }
      </div>
    }
  }

  protected isAuthenticateButtonEnabled(): boolean {
    return !this.state.isConnecting && this.systemApiAttribute.tenant_name
  }

  protected richIntegrationCheckBoxChange(isRichSync) {
    const newSystemApiAttribute = {
      ...JSON.parse(this.props.data.system_api_attribute),
      is_full_integration: isRichSync,
    }
    this.props.propertiesChanged({
      system_api_attribute: JSON.stringify(newSystemApiAttribute),
    })
    if (this.props.data.is_active) {
      this.props.saveRecord(this.props.data.id)
        .then((result) => {
          this.props.context.store.dispatch(Flash.showFlashMessage(Integrations.notificationMessages.UpdateSuccess))
        })
    }
  }
}
